import React from 'react'
import Form, { Input } from '../../../../../../components/Form'
import { genericMaskWithTwoZero } from '../../../../../../utlis/mask'
import { Volume } from '../../View'

interface TypesFormProps {
  volumes: Volume[]
  volumesErrors: any[]
  setVolumes: React.Dispatch<React.SetStateAction<Volume[]>>
  setVolumesErrors: any
}

export const FormC = ({
  setVolumes,
  volumes,
  volumesErrors,
  setVolumesErrors
}: TypesFormProps): JSX.Element => {
  return (
    <Form>
      <div
        style={{
          width: '800px',
          maxHeight: '500px',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        <div className="row">
          <div className="col-md-12">
            {volumes?.map((volume: any, index) => (
              <div className="row" key={index} style={{ display: 'flex' }}>
                <div
                  className="col-md-1"
                  style={{
                    marginTop: '20px',
                    marginLeft: '20px',
                    paddingRight: '0px'
                  }}
                >
                  {index + 1}
                </div>
                <div
                  className="col-md-11"
                  style={{ marginLeft: '-20px', paddingLeft: '0px' }}
                >
                  <div className="col-md-2" style={{ width: '140px' }}>
                    <Input
                      label="Peso"
                      name={`weight_${index}`}
                      className="form-control"
                      hasError={volumesErrors[index]?.weight}
                      onChange={e =>
                        setVolumes(prev => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].weight = genericMaskWithTwoZero(
                            e.target.value
                          )
                          return copy
                        })
                      }
                      price
                    />
                  </div>
                  <div className="col-md-2" style={{ width: '140px' }}>
                    <Input
                      label="Altura"
                      name={`height_${index}`}
                      className="form-control"
                      hasError={volumesErrors[index]?.height}
                      onChange={e =>
                        setVolumes(prev => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].height = genericMaskWithTwoZero(
                            e.target.value
                          )
                          return copy
                        })
                      }
                      price
                    />
                  </div>
                  <div className="col-md-2" style={{ width: '140px' }}>
                    <Input
                      label="Comprimento"
                      name={`length_${index}`}
                      className="form-control"
                      hasError={volumesErrors[index]?.length}
                      onChange={e =>
                        setVolumes(prev => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].length = genericMaskWithTwoZero(
                            e.target.value
                          )
                          return copy
                        })
                      }
                      price
                    />
                  </div>
                  <div className="col-md-2" style={{ width: '140px' }}>
                    <Input
                      label="Largura"
                      name={`width_${index}`}
                      className="form-control"
                      hasError={volumesErrors[index]?.width}
                      onChange={e =>
                        setVolumes(prev => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].width = genericMaskWithTwoZero(
                            e.target.value
                          )
                          return copy
                        })
                      }
                      price
                    />
                  </div>
                  <div className="col-md-2" style={{ width: '140px' }}>
                    <Input
                      label="Quantidade"
                      name={`quantity_${index}`}
                      className="form-control"
                      hasError={volumesErrors[index]?.quantity}
                      onChange={e =>
                        setVolumes(prev => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].quantity = Number(e.target.value)
                          return copy
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="col-md-12">
            <button
              onClick={() => {
                setVolumesErrors((prev: any) => {
                  const copy = JSON.parse(JSON.stringify(prev))
                  return [
                    ...copy,
                    {
                      weight: {
                        message: 'O campo é obrigatório!',
                        error: false
                      },
                      height: {
                        message: 'O campo é obrigatório!',
                        error: false
                      },
                      length: {
                        message: 'O campo é obrigatório!',
                        error: false
                      },
                      width: {
                        message: 'O campo é obrigatório!',
                        error: false
                      },
                      quantity: {
                        message: 'O campo é obrigatório!',
                        error: false
                      }
                    }
                  ]
                })
                setVolumes(prev => {
                  const copy = JSON.parse(JSON.stringify(prev))
                  return [
                    ...copy,
                    {
                      weight: '',
                      height: '',
                      length: '',
                      width: '',
                      quantity: ''
                    }
                  ]
                })
              }}
              type="button"
              className="btn dark btn-sm sbold uppercase"
            >
              <span
                className="fa fa-plus"
                aria-hidden="true"
                style={{ marginRight: '5px' }}
              />
              Volume
            </button>
          </div>
        </div>
      </div>
    </Form>
  )
}
