import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import logoImg from '../../assets/logo-multfluxo-preto-3.png'
import AvatarImg from '../../assets/layouts/layout3/img/avatar.png'
import { AvatarCircle, WrapperButtons, ContainerHeader } from './styles'
import { Task } from '../../pages/PersonelDepartment/Tasks/List'
import api from '../../services/api'
import moment from 'moment'
import 'moment/locale/pt-br'
import {
  CURRENT_TAB_TASK,
  DATE_MASK_LOCALE_WITH_TIME
} from '../../common/constants'
import { useOutsideClick } from '../../hooks/outSideClick'
import { StringParam, useQueryParam } from 'use-query-params'

function formatTimeDifference(
  startDate: moment.Moment,
  endDate: moment.Moment
) {
  const duration = moment.duration(endDate.diff(startDate))

  if (duration.asSeconds() < 60) {
    return `${Math.floor(duration.asSeconds())} segundo${
      Math.floor(duration.asSeconds()) !== 1 ? 's' : ''
    }`
  } else if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())} minuto${
      Math.floor(duration.asMinutes()) !== 1 ? 's' : ''
    }`
  } else if (duration.asHours() < 24) {
    return `${Math.floor(duration.asHours())} hora${
      Math.floor(duration.asHours()) !== 1 ? 's' : ''
    }`
  } else {
    return `${Math.floor(duration.asDays())} dia${
      Math.floor(duration.asDays()) !== 1 ? 's' : ''
    }`
  }
}

interface Menu {
  id: number
  parent_id?: number
  method?: string
  name: string
  url?: string
  permission: boolean
  children?: Menu[]
  order_position?: number
  type: string
}

function taskMessage(task: any) {
  if (task.comments?.[0]?.type === 'update' && task.status !== 'finished') {
    return 'Tarefa atualizada por '
  }
  if (task.comments?.[0]?.type === 're-open' && task.status !== 'finished') {
    return 'Tarefa reaberta por '
  }
  if (task.comments?.[0] && task.status !== 'finished') {
    if (task.comments?.[0]?.message?.toLowerCase().includes('concluida')) {
      return 'Tarefa finalizada por '
    }
    return 'Tarefa atualizada por '
  }
  if (task.comments?.[0] && task.status === 'finished') {
    return 'Tarefa finalizada por '
  }
  if (!task.comments?.length && task.status === 'finished') {
    return 'Tarefa finalizada por '
  }
  return 'Tarefa criada por '
}

const MenuLink = (menu: { type: string; url: string; menuName?: string }) => {
  if (menu.type === 'cake') {
    return (
      <a href={menu.url ? 'http://multfluxosistema.com.br' + menu.url : '#!'}>
        {menu.menuName}
      </a>
    )
  }
  if (menu.type === 'front-v2') {
    return (
      <a
        href={
          menu.url ? 'https://app-v2.multfluxosistema.com.br' + menu.url : '#!'
        }
      >
        {menu.menuName}
      </a>
    )
  }
  return <Link to={menu.url ? menu.url : '#!'}>{menu.menuName}</Link>
}

const Header: React.FC = () => {
  const [openNotifications, setOpenNotifications] = useState(false)
  const [hasPermission, setHasPermission] = useState(false)
  const [pendingTasks, setPendingTasks] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { signOut, user, menus } = useAuth()
  const { addToast } = useToast()
  const history = useHistory()
  const [queryForceReload] = useQueryParam('force_reload', StringParam)
  const containerDropdownNotifyRef = useRef(null)
  useOutsideClick(containerDropdownNotifyRef, () => {
    setOpenNotifications(false)
  })
  const [tasks, setTasks] = useState<Task[]>([])
  const createMenu = (arrayMenus: Menu[], submenu = false) => {
    const copyMenus = arrayMenus.sort((menu, nextMenu) => {
      return menu?.name?.localeCompare(nextMenu?.name)
    })
    return copyMenus
      .filter(
        menu =>
          (!menu.method && menu.children) ||
          (menu.method && menu.permission === true)
      )
      .map(menu => {
        const className = menu.url ? '' : 'dropdown-submenu'
        let showMenu = true
        if (menu.method === null && (menu.children || menu.url)) {
          if (menu.children) {
            const subMenus = menu.children.filter(
              sub => sub.permission === true
            )
            showMenu = subMenus.length > 0
          }
          showMenu = true
        }
        if (showMenu && menu.permission) {
          const menuName = !submenu
            ? menu.name
            : menu.name[0].toUpperCase() +
              menu.name.substring(1, menu.name.length).toLowerCase()
          return (
            <li
              className={
                !submenu ? 'menu-dropdown classic-menu-dropdown' : className
              }
              key={menu.id}
            >
              <MenuLink type={menu.type} url={menu.url} menuName={menuName} />
              {menu.children && (
                <ul className="dropdown-menu pull-left">
                  {createMenu(menu.children, true)}
                </ul>
              )}
            </li>
          )
        }
        return ''
      })
  }

  const handleLogout = useCallback(() => {
    window.location.href = 'https://app-v2.multfluxosistema.com.br/logout'
    // addToast({
    //   type: 'info',
    //   title: 'Logout realizado com sucesso!'
    // })
  }, [])

  const removeQueryParam = useCallback(
    queryParam => {
      const currentUrl = new URL(window.location.href)
      const params = new URLSearchParams(currentUrl.search)

      if (params.has(queryParam)) {
        params.delete(queryParam)

        history.replace({
          pathname: currentUrl.pathname,
          search: params.toString()
        })
      }
    },
    [history]
  )

  const getTasks = useCallback(async () => {
    setIsLoading(true)
    if (!hasPermission) return
    const { data } = await api.get<Task[]>('/personelDepartment/tasks')
    removeQueryParam('force_reload')
    const tasks = data.filter(task => {
      const taskUser = task?.taskUsers?.find(
        taskUser => Number(taskUser.user_id) === Number(user.id)
      )
      if (
        !taskUser?.accepted &&
        Number(task.userDemandant.id) !== Number(user.id)
      ) {
        return true
      }
      if (!task?.comments?.[0] && task.read_at) {
        return false
      }
      if (task.status === 'finished') {
        return false
      }
      if (task?.comments?.[0]) {
        if (task.comments?.[0].read_at) {
          return false
        }
        if (task?.comments?.[0]?.user?.id === Number(user?.id)) {
          return false
        }
      } else {
        if (task.userDemandant.id === Number(user.id)) {
          return false
        }
      }
      return true
    })

    const hasNews = tasks.some(task => {
      const taskUser = task?.taskUsers?.find(
        taskUser => Number(taskUser.user_id) === Number(user.id)
      )
      if (!taskUser?.accepted) {
        if (task.created_at) {
          const diff = moment().diff(
            moment(task.created_at, DATE_MASK_LOCALE_WITH_TIME),
            'days'
          )
          console.log(task.created_at)
          console.log(diff)
          if (diff === 0 || diff === 3) {
            return true
          }
        }
      }

      if (task.created_at && !taskUser?.accepted && !task.comments?.length) {
        const diff = moment().diff(
          moment(task.created_at, DATE_MASK_LOCALE_WITH_TIME),
          'days'
        )
        if (diff === 3) {
          return true
        }
      }
      if (task.comments?.[0]) {
        if (task.comments?.[0].created_at) {
          const diff = moment().diff(
            moment(task.comments?.[0].created_at, DATE_MASK_LOCALE_WITH_TIME),
            'days'
          )
          if (diff === 0 || diff === 3) {
            return true
          }
        }
      }

      return false
    })

    setPendingTasks(
      data.filter(task => {
        return task.status === 'in-progress' || task.status === 'to-do'
      }).length
    )
    console.log(tasks)
    setTasks(
      tasks
        .sort((currentTask, nextTask) =>
          moment(
            nextTask.comments?.[0]
              ? nextTask.comments?.[0].created_at
              : nextTask.created_at,
            DATE_MASK_LOCALE_WITH_TIME
          ).diff(
            moment(
              currentTask.comments?.[0]
                ? currentTask.comments?.[0].created_at
                : currentTask.created_at,
              DATE_MASK_LOCALE_WITH_TIME
            )
          )
        )
        .slice(-10)
    )
    setOpenNotifications(hasNews)
    setIsLoading(false)
  }, [hasPermission, removeQueryParam, user.id])

  const checkIfHasPermission = useCallback(async () => {
    setIsLoading(true)
    const response = await api.post('checkPermission', {
      method: 'GET',
      originalUrl: '/personelDepartment/tasks',
      role_id: user.role_id,
      user_id: user.id
    })
    setHasPermission(response.data.approved)
    setIsLoading(false)
  }, [user.id, user.role_id])

  useEffect(() => {
    getTasks()
  }, [getTasks, hasPermission, queryForceReload])
  useEffect(() => {
    checkIfHasPermission()
  }, [checkIfHasPermission])
  const TextTasksPending = () => {
    if (pendingTasks === 0) {
      return (
        <h3 style={{ color: '#c3c3c3' }}>
          Você <strong>não</strong> tem tarefas pendentes
        </h3>
      )
    }
    if (pendingTasks === 1) {
      return (
        <h3 style={{ color: '#c3c3c3' }}>
          Você tem <strong>{pendingTasks} tarefa pendente</strong>
        </h3>
      )
    }
    return (
      <h3 style={{ color: '#c3c3c3' }}>
        Você tem <strong>{pendingTasks} tarefas pendentes</strong>
      </h3>
    )
  }

  return (
    <ContainerHeader className="page-header">
      <div className="page-header-top">
        <div className="container-fluid">
          <div className="page-logo">
            <a href="https://multfluxosistema.com.br/">
              <img
                src={logoImg}
                alt="logo"
                width={190}
                height={53}
                style={{ objectFit: 'cover' }}
                className="logo-default logo-light"
              />
            </a>
          </div>
          <div className="page-header-menu">
            <div className="hor-menu">
              <ul className="nav navbar-nav">{createMenu(menus)}</ul>
            </div>
          </div>
          <Link to="#!" className="menu-toggler" />
          <WrapperButtons className="top-menu">
            <ul className="nav navbar-nav pull-right">
              {hasPermission && (
                <li
                  className={`dropdown dropdown-extended dropdown-notification dropdown-dark ${
                    openNotifications ? ' open' : ' no-open'
                  }`}
                  onClick={() => setOpenNotifications(!openNotifications)}
                  id="header_notification_bar"
                  ref={containerDropdownNotifyRef}
                >
                  <a
                    href="javascript:;"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                    data-close-others="true"
                    aria-expanded="false"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <i className="icon-bell" style={{ fontSize: '20px' }}></i>
                    {!!tasks?.length && (
                      <span
                        className="badge badge-default"
                        style={{ backgroundColor: '#EE8F20' }}
                      >
                        {tasks?.length}
                      </span>
                    )}
                  </a>
                  <ul
                    className="dropdown-menu"
                    style={{
                      maxWidth: '500px',
                      width: '500px',
                      background: '#242424'
                    }}
                  >
                    {!isLoading && (
                      <>
                        <li
                          className="external"
                          style={{ background: '#242424', color: '#c3c3c3' }}
                        >
                          <TextTasksPending />
                          <a
                            href="/personelDepartment/tasks"
                            style={{ color: '#EE8F20', fontSize: '14px' }}
                          >
                            Ver todas as tarefas
                          </a>
                        </li>
                        <li>
                          <ul
                            className="dropdown-menu-list scroller"
                            style={{ maxHeight: '300px', overflow: 'auto' }}
                          >
                            {tasks?.map(task => {
                              const isMessage = !!task.comments?.[0]
                              const splitedName = isMessage
                                ? task.comments?.[0]?.user.name.split(' ')
                                : task.userDemandant.name.split(' ')
                              return (
                                <li
                                  key={task.id}
                                  style={{
                                    marginTop: '5px',
                                    cursor: 'pointer',
                                    padding: '5px'
                                  }}
                                  onClick={async () => {
                                    localStorage.setItem(
                                      CURRENT_TAB_TASK,
                                      task.userDemandant.id === Number(user?.id)
                                        ? '1'
                                        : '0'
                                    )
                                    if (isMessage) {
                                      await api.patch(
                                        `/personelDepartment/comments/markRead/${task.comments?.[0]?.id}`
                                      )
                                    } else {
                                      await api.patch(
                                        `/personelDepartment/tasks/markRead/${task?.id}`
                                      )
                                    }
                                    const a = document.createElement('a')
                                    const owner =
                                      task.userDemandant.id === Number(user?.id)
                                        ? 'me'
                                        : 'other'
                                    const status =
                                      task.re_open &&
                                      task.status === 'in-progress'
                                        ? 're-open'
                                        : task.status

                                    a.href = `/personelDepartment/tasks/?status=${status}&id=${task.id}&owner=${owner}`
                                    document.body.appendChild(a)
                                    a.click()
                                    document.body.removeChild(a)
                                  }}
                                  className="container-notify-hover"
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      padding: '5px 5px'
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: '10px'
                                      }}
                                    >
                                      <img
                                        className="notify-avatar"
                                        src={
                                          task.userDemandant.avatar_url ||
                                          AvatarImg
                                        }
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start'
                                      }}
                                    >
                                      <span>
                                        {taskMessage(task)}
                                        {/* {task.comments?.[0] &&
                                        task.status !== 'finished'
                                          ? 'Mensagem de '
                                          : task.status === 'finished'
                                          ? 'Tarefa concluída por '
                                          : 'Tarefa criada por '} */}
                                        <span
                                          style={{ textTransform: 'uppercase' }}
                                        >
                                          {isMessage
                                            ? `${splitedName?.[0]} ${
                                                splitedName.length > 1
                                                  ? splitedName[
                                                      splitedName.length - 1
                                                    ]
                                                  : ''
                                              }`
                                            : task.userDemandant.name}
                                        </span>
                                      </span>
                                      <span>{task.title}</span>
                                    </div>
                                    <span
                                      className="time"
                                      style={{
                                        textAlign: 'end',
                                        marginLeft: 'auto'
                                      }}
                                    >
                                      {formatTimeDifference(
                                        moment(
                                          task.comments?.[0]
                                            ? task.comments?.[0].created_at
                                            : task.created_at,
                                          DATE_MASK_LOCALE_WITH_TIME
                                        ).add(3, 'hours'),
                                        moment()
                                      )}
                                    </span>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}
              <li className="dropdown dropdown-user dropdown-dark">
                <Link to="/users/profile" className="dropdown-toggle">
                  <AvatarCircle
                    alt=""
                    className="img-circle"
                    src={user.avatar_url ? user.avatar_url : AvatarImg}
                  />
                  <span className="username">{user.name}</span>
                </Link>
              </li>
              <li className="dropdown dropdown-extended">
                <Link
                  to="#!"
                  className="dropdown-toggle"
                  onClick={e => {
                    e.preventDefault()
                    handleLogout()
                  }}
                >
                  <span className="sr-only">Toggle Quick Sidebar</span>
                  <i className="icon-logout" />
                </Link>
              </li>
            </ul>
          </WrapperButtons>
        </div>
      </div>
    </ContainerHeader>
  )
}

export default Header
