import React, { useMemo } from 'react'
import { Tab } from './tabs/index'
import { ClientProvider } from './providers/ClientProvider'
import Container from '../../../../components/Container'
import { breadcrumbView } from '../domain/breadcrumb/view'
import { namePageTitle, nameActions } from '../domain/info'
import { TabsProvider } from '../../../../hooks/tabs'
import { tabsList } from './tabs/tabsList'
import { useParams } from 'react-router-dom'
import {
  toolsViewCreate,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { ALLOWED_ROLES_CREATE_BUDGET } from '../../../../common/constants'
import { useAuth } from '../../../../hooks/auth'

const Viewclient = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { user } = useAuth()
  const toolsData = useMemo(() => {
    if (ALLOWED_ROLES_CREATE_BUDGET.includes(user?.role_id)) {
      return [
        {
          name: 'Venda',
          to: '#',
          handleOnClick: () => {
            window.open(
              `https://app-v2.multfluxosistema.com.br/commercial/budgets/sale/create/${id}`,
              '_blank'
            )
          },
          icon: 'fa fa-plus'
        },
        {
          name: 'Locação',
          to: '#',
          handleOnClick: () => {
            window.open(
              `https://app-v2.multfluxosistema.com.br/commercial/budgets/location/create/${id}`,
              '_blank'
            )
          },
          icon: 'fa fa-plus'
        },
        toolsViewUpdate(id),
        toolsViewCreate(),
        toolsViewList()
      ]
    }
    return [toolsViewUpdate(id), toolsViewCreate(), toolsViewList()]
  }, [id, user?.role_id])

  return (
    <TabsProvider>
      <ClientProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={toolsData}
        >
          <Tab tabList={tabsList} id={id} />
        </Container>
      </ClientProvider>
    </TabsProvider>
  )
}

export default Viewclient
