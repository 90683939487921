import React, { useEffect, useRef, useState } from 'react'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'

import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'

import {
  genericMaskWithTwoZeroWithPoint,
  removeCurrencyMask
} from '../../../../../utlis/mask'
import { Alert } from '../../../../../components/Alert'
import moment from 'moment'
import {
  DATE_MASK_LOCALE_WITHOUT_TIME,
  DATE_MASK_LOCALE_WITH_TIME
} from '../../../../../common/constants'

type InvestmentData = {
  id: number
  company_id?: number
  category_id?: string
  category_name?: string
  sub_category_name?: string
  bank_account_id?: string
  bank_account_name?: string
  investment_origin_id?: string
  financial_transaction_id?: string
  lastInvestment?: InvestmentData
  name?: string
  iof?: string
  income_tax?: string
  date?: string
  observations?: string
  value?: string
  balance?: string
  operation?: string
  user_id?: number
  user_name?: string
  sub_category_id?: string
}

type IsOpenInModalProps = {
  handleOnClose: () => void
}

type TypesFormProps = {
  initialValues?: InvestmentData & {
    idUpdate?: number
  }
  isOpenInModal?: IsOpenInModalProps
  typeForm: 'create' | 'update' | 'transaction'
}

export const FormContributionUpdate = ({
  typeForm,
  initialValues,
  isOpenInModal
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<InvestmentData>()

  const [dateValue, setDateValue] = useState<Date>()

  const [operation, setOperation] = useState<string>(
    typeForm === 'transaction' ? 'AP' : 'A'
  )
  const [value, setValue] = useState<string>('0,00')

  const buttonSubmitRef = useRef<HTMLButtonElement>()
  const [alert, setAlert] = useState(false)

  useEffect(() => {
    if (initialValues) {
      if (initialValues?.date) {
        setDateValue(
          moment(initialValues.date, DATE_MASK_LOCALE_WITH_TIME)
            .add(1, 'd')
            .toDate()
        )
      }
      delete initialValues.date
      setDefaultValues({
        ...initialValues
      })
      setOperation(initialValues.operation)
      setValue(genericMaskWithTwoZeroWithPoint(initialValues.value))
    }
  }, [initialValues])

  const onSubmit = async (data: InvestmentData) => {
    const requestDateValue = moment(
      dateValue.toLocaleDateString(),
      DATE_MASK_LOCALE_WITHOUT_TIME
    ).toISOString()

    if (!dateValue) {
      addToast({
        title: 'Campo obrigatório',
        description: 'Campo data é obrigatório',
        type: 'info'
      })
      return
    }
    const dataUpdate = {
      observations: data.observations,
      date: requestDateValue,
      value: removeCurrencyMask(data?.value)
    }
    const id = initialValues?.id

    try {
      activeLoading()
      await api.put(
        `financial/investments/updateContribution/${id}`,
        dataUpdate
      )
      updateDataTable()
      disableLoading()
      isOpenInModal?.handleOnClose?.()
      addToast({
        type: 'success',
        title: 'Registro atualizado',
        description: 'Registro alterado com sucesso'
      })
    } catch (error) {
      isOpenInModal?.handleOnClose?.()
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o registro',
        description:
          'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
      })
    }
    disableLoading()
  }

  const handlerOnClickButtonCancel = () => {
    setAlert(false)
  }

  const handlerOnClickButtonConfirm = async () => {
    buttonSubmitRef?.current?.click()
    setAlert(false)
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <div className="row">
        <div className="col-md-6">
          <Select
            label="Operação"
            className="form-control"
            name="operation"
            options={[
              {
                name: 'APORTE',
                value: 'CT'
              },
              {
                name: 'ATUALIZAÇÃO',
                value: 'AP'
              },
              {
                name: 'RESGATE',
                value: 'R'
              }
            ]}
            value={operation}
            onChange={event => {
              setOperation(event.target.value)
            }}
            controlled
            blank
            disabled
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-6">
          <DatePicker
            name="date"
            className="form-control"
            label="Data (Data que aparece no extrato bancário)"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={dateValue}
            maxDate={moment().toDate()}
            onChange={date => {
              setDateValue(date)
            }}
            controlled
            rules={{ required: false }}
          />
        </div>
        <div className="col-md-6">
          <Input
            label={
              ['AP', 'CT'].includes(operation) ? 'Valor bruto' : 'Valor liquido'
            }
            name="value"
            value={value}
            onChange={event => {
              setValue(genericMaskWithTwoZeroWithPoint(event.target.value))
            }}
            className="form-control"
            rules={{ required: true }}
            controlled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Textarea
            label="Observação"
            name="observations"
            className="form-control"
            rules={{ required: false }}
            rows={3}
          />
        </div>
      </div>
      <div className="form-actions right">
        <button
          type="submit"
          className="invisible position-absolute"
          ref={buttonSubmitRef}
        ></button>
        <button
          type="button"
          className="btn dark btn-sm bold uppercase"
          onClick={() => {
            if (operation !== 'AP' && typeForm === 'create') {
              setAlert(true)
              return
            }
            buttonSubmitRef?.current?.click()
          }}
        >
          Salvar
        </button>
      </div>
      <Alert
        message={'Confirma a realização da transação financeira ?'}
        onClickCancellButton={handlerOnClickButtonCancel}
        onClickConfirmButton={handlerOnClickButtonConfirm}
        isActive={alert}
      />
    </Form>
  )
}
