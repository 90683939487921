import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import {
  FreightLocalizationPercentage,
  nameActions,
  states
} from '../../domain/info'
import axios from 'axios'
import {
  BRL,
  genericMaskWithTwoZero,
  zipCodeMask
} from '../../../../../utlis/mask'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  typeForm: 'create' | 'update'
  freightLocalizationPercentage?: FreightLocalizationPercentage
  parent?: FreightLocalizationPercentage
}

export const FormCategory = ({
  isOpenInModal,
  typeForm,
  freightLocalizationPercentage,
  parent
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] =
    useState<FreightLocalizationPercentage>()
  const [zipCode, setZipCode] = useState('')
  const [localization, setLocalization] = useState('')
  const [code, setCode] = useState('')
  const [percentage, setPercentage] = useState('')
  const [averageDeadline, setAverageDeadline] = useState('')

  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (freightLocalizationPercentage) {
      setDefaultValues({
        ...freightLocalizationPercentage,
        percentage: genericMaskWithTwoZero(
          freightLocalizationPercentage.percentage
        )
      })
      setAverageDeadline(freightLocalizationPercentage?.average_deadline)
      setPercentage(
        genericMaskWithTwoZero(freightLocalizationPercentage.percentage)
      )
    }
  }, [freightLocalizationPercentage, typeForm])

  const onSubmitForm = async (data: any) => {
    try {
      data.cep = undefined
      data.average_deadline = averageDeadline ? Number(averageDeadline) : null
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose, idParent } = isOpenInModal
          const dataCreate = {
            ...data,
            type: 'city',
            parent_id: idParent,
            percentage: BRL(data.percentage).value
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate = {
              ...data,
              type: 'state',
              percentage: BRL(data.percentage).value
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const id = freightLocalizationPercentage.id
          const dataUpdate = {
            ...data,
            type: 'city',
            percentage: BRL(data.percentage).value
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data,
            type: 'state',
            percentage: BRL(data.percentage).value
          }
          const id = freightLocalizationPercentage.id

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  const searchZipCode = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${zipCode}/json/`
      )
      if (data.erro) {
        addToast({
          type: 'error',
          title: 'CEP inválido',
          description: 'CEP inválido, por favor, tente novamente.'
        })
        setLocalization('')
        setCode('')
        setDefaultValues(prev => ({
          ...prev,
          code: '',
          localization: ''
        }))
      }
      if (!data.erro) {
        setLocalization(data.localidade)
        setCode(data.ibge)
        if (data.uf !== parent?.code) {
          addToast({
            type: 'error',
            title: 'UF do CEP diferente do estado selecionado.',
            description: 'Por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao consultar CEP',
        description:
          'Erro ao consultar o CEP na api ViaCEP, por favor, tente novamente.'
      })
      disableLoading()
    }
  }, [addToast, disableLoading, parent, zipCode])

  useEffect(() => {
    if (zipCode.length === 9) {
      activeLoading()
      searchZipCode()
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading, searchZipCode, zipCode])

  useEffect(() => {
    if (isOpenInModal && typeForm === 'update') return
    setDefaultValues(prev => ({
      ...prev,
      cep: '',
      percentage: '',
      code: '',
      localization: ''
    }))

    setZipCode('')
    setLocalization('')
    setCode('')
    setPercentage('')
  }, [isOpenInModal, typeForm])

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <div className="row">
        {isOpenInModal && (
          <div className="form-content col-md-3">
            <Input
              name="cep"
              className="form-control"
              label="CEP"
              value={zipCode}
              onChange={e => {
                setZipCode(zipCodeMask(e.target.value))
              }}
              maxLength={9}
              onKeyPress={event => {
                const regex = /^[0-9.]+$/
                if (!regex.test(event.key)) event.preventDefault()
              }}
              controlled
            />
          </div>
        )}
        {isOpenInModal ? (
          <div className="form-content col-md-3">
            <Input
              name="localization"
              className="form-control"
              label="Nome"
              value={localization}
              readOnly={true}
              rules={{ required: true }}
              controlled
            />
          </div>
        ) : (
          <div className="form-content col-md-3">
            <Select
              name="localization"
              options={states.map(state => ({
                name: state.name,
                value: state.name
                  .toUpperCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .trim()
              }))}
              className="form-control"
              label="Nome"
              value={localization}
              onChange={e => {
                setLocalization(e.target.value)
                const code = states.find(
                  state =>
                    state.name
                      .toUpperCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      .trim() === e.target.value
                )
                if (code) {
                  setCode(code.uf)
                }
              }}
              rules={{ required: true }}
              blank
              defaultValue={''}
              controlled
            />
          </div>
        )}
        <div className="form-content col-md-1">
          <Input
            name="code"
            className="form-control"
            label={isOpenInModal ? 'Código IBGE' : 'UF'}
            value={code}
            readOnly={true}
            rules={{ required: true }}
            controlled
          />
        </div>
        <div className="form-content col-md-1">
          <Input
            name="percentage"
            className="form-control"
            label="Percentual"
            value={percentage}
            onChange={e => {
              setPercentage(
                BRL(genericMaskWithTwoZero(e.target.value)).format()
              )
            }}
            onKeyPress={event => {
              const regex = /^[0-9.]+$/
              if (!regex.test(event.key)) event.preventDefault()
            }}
            rules={{ required: true }}
            controlled
          />
        </div>
        <div className="form-content col-md-2">
          <Input
            name="average_deadline"
            className="form-control"
            label="Prazo médio em dias"
            rules={{ required: false }}
            value={averageDeadline}
            onChange={e => {
              const value = e.target.value

              setAverageDeadline(value?.replace(/\D/g, ''))
            }}
            maxLength={3}
          />
        </div>
      </div>

      {isOpenInModal && (
        <hr
          className="divider"
          style={{
            position: 'relative',
            width: 'calc(100% + 28px)',
            transform: 'translateX(-14px)',
            borderTop: '1px solid #e5e5e5'
          }}
        />
      )}
      <div className="form-actions right">
        {isOpenInModal && (
          <button
            onClick={isOpenInModal.handleOnClose}
            type="reset"
            className="btn btn-default btn-sm sbold uppercase"
          >
            Fechar
          </button>
        )}
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
