import React, { useEffect } from 'react'
import { useAuth } from '../../hooks/auth'

const Logout: React.FC = () => {
  const { signOut } = useAuth()
  useEffect(() => {
    signOut()
    window.location.href = 'https://multfluxosistema.com.br/usuarios/logout'
  }, [signOut])

  return <></>
}
export default Logout
